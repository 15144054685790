@import "../../../../node_modules/bulma/sass/utilities/initial-variables.sass";

// Data table variables
$table-striped-row-even-background-color: $white-ter;
$table-head-background-color: $white;
$table-background-color: $white;
$table-head-cell-border-width: 0 0 1px !important;
$table-cell-border-width: 0px !important;

// Card variables
$card-background-color: $white;

// Input variable
$input-background-color: $white;
