@import "../../../resources/scss/1-helpers/mixins";

.container {
  width: 100%;

  @include tablet {
    width: 80%;
  }

  @include desktop {
    width: 60%;
  }

  & button {
    @include tablet {
      min-width: 220px;
    }
  }
}

.text {
  transition: 0.2s;
}

.hide {
  opacity: 0;
}

.show {
  opacity: 1;
}
