//Colors
$scheme-main: #f0f0f0;
$scheme-invert: black;
$dark-gray: #d4d2d2;
$light-blue: #3e8ed030;
$blue-info: #3e8ed0;
$red-danger: #f14668;
$yellow-warnign: #f1c40f;
$green-primary: #00d1b2;

$zamtest-blue: #4a89be;

$info: $zamtest-blue;
$warning: $yellow-warnign;
//z-index
$top: 1000;

//-----Colors

//Scheme

$scheme-main: #f0f0f0 !default;
$scheme-secondary: #1165ed !default;
//Basics

$black: #212120 !default;
$white: #fff !default;

//Grey scale

$grey-darker: hsl(0, 0%, 21%) !default;
$grey-dark: hsl(0, 0%, 29%) !default;
$grey: hsl(0, 0%, 35%) !default;
$grey-light: hsl(0, 0%, 50%) !default;
$grey-lighter: hsl(0, 0%, 75%) !default;
$grey-lightest: hsl(0, 0%, 93%) !default;

//-----Responsive Sizes

$breakpoint-offset: 40px;
$tablet: 680px !default;
$desktop: 1007px + (2 * $breakpoint-offset) !default;
$widescreen: 1300px + (2 * $breakpoint-offset) !default;
$full-hd: 1408px + (2 * $breakpoint-offset) !default;

//-----Animations

$transition-default: 300ms all;
