.operation-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.operation {
  & span {
    margin-left: 10px;
  }
}
