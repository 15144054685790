.navbar {
  min-width: 150px;
  width: 100%;
  background-color: $zamtest-blue;
  transition: 100ms all;
  box-shadow: 5px 0px 13px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 0px 13px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 0px 13px 1px rgba(0, 0, 0, 0.75);

  & > * {
    color: $white;
  }
}

.navbar_eqspaced {
  justify-content: center;
  position: sticky;
  z-index: 99;
  padding: 0px;

  & h1 {
    margin-top: 10px;

    @include mobile {
      display: none;
    }
  }
}

.link-pages {
  padding-left: 30px;
  padding-top: 5px;
  background: $zamtest-blue;
  width: 100%;
  display: flex;

  & a {
    text-decoration: none;
    color: $scheme-main;
    &:hover {
      color: black;
      background: $zamtest-blue;
    }
  }

  & span {
    font-size: 20px;
  }
}

.button-options {
  background: $zamtest-blue;
  color: white !important;
  font-weight: bold;
  border-color: white !important;
}

.button-options:hover {
  background: black !important;
}
