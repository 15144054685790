.closeBtn {
  color: $info;
}

.cardColor {
  background: $light-blue;
}

.columnFlex {
  display: flex;
  flex-direction: column;
}

.sendCloseBtns {
  display: flex;
  justify-content: space-around;
  margin-top: auto;
}
