.timeLineFlex {
  display: flex;

  & .timeLineFlexChild {
    display: none;
    @include desktop {
      display: flex;
    }

    @include tablet {
      display: flex;
    }
  }
}

.datePosition {
  position: relative;
  top: calc(50% - 15px);
  width: 80px;
}

.allHeight {
  height: 100%;
}

.iconContainer {
  border-radius: 999px;
  background: #000;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: calc(50% - 20px);

  & span {
    color: #fff;
  }
}

.line {
  border-left: 2px solid black;
  height: calc(100% - 20px);
  position: relative;
  left: 50%;
  top: calc(50% - 20px);
}

.allWidth {
  width: 100%;
  background: $light-blue !important;
}

.iconHour {
  align-items: center;
  color: gray;
}
