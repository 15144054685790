.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  visibility: hidden;
}

.modal.show {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
}

.modal-content {
  width: 60%;
  background-color: #fff;
  transform: translateY(-200px);
  transition: all 0.3s ease-in-out;
}

.modal.show .modal-content {
  transform: translateY(0);
}

.modal-header {
  padding: 10px 5px;
  justify-content: center;
}

.modal-footer {
  padding: 10px 25px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-title {
  margin: 0;
  font-size: $size-4;
}

.modal-body {
  text-align: center;
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: center;
  & .icon_modal {
    height: 10vh;

    @include desktop {
      height: 15vh;
    }
  }
}

.loader {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid $scheme-invert;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 0.6s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
