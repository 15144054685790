$body-size: 18px;

//Button Color
$button-color: white;
$button-background-color: $scheme-invert;

$button-hover-color: white;
$button-hover-background: #333333;

.input,
.textarea,
.select select {
  background-color: #ffffff;
  border-color: gray;
  border-radius: 4px;
  color: #363636;
}

img {
  object-fit: cover;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: #00d1b236 !important;
}

.loggedInPage {
  width: 95vw;
  margin: 20px auto 20px;
  height: auto;
}

.border-box {
  border: 1px solid $dark-gray;
  border-radius: 6px;
}

.is-fullheight {
  height: 100% !important;
}
