$spacers: 5 12 16 24 30;

@mixin display-flex($columns: false) {
  display: flex;
  @if ($columns) {
    flex-direction: column;
  }
}

@each $spacer in $spacers {
  .vertical-margin-#{$spacer} {
    margin-top: $spacer * 1px;
    margin-bottom: $spacer * 1px;
  }

  .horizontal-margin-#{$spacer} {
    margin-left: $spacer * 1px;
    margin-right: $spacer * 1px;
  }

  .vertical-padding-#{$spacer} {
    padding-top: $spacer * 1px;
    padding-bottom: $spacer * 1px;
  }

  .horizontal-padding-#{$spacer} {
    padding-left: $spacer * 1px;
    padding-right: $spacer * 1px;
  }
}

.form-container {
  margin: 5px auto;
  width: 65vw;
}

.flex-row {
  @include display-flex;
}

.flex-column {
  @include display-flex(true);
}

.full-width {
  width: 100vw;
}

.buttonContainer {
  display: flex;
  width: 100%;

  & > div {
    margin-right: 0;
    margin-left: auto;
    & > button {
      margin-left: 5px;
    }
  }
}

.emptyContainer {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  background: white;
}

.whiteBox {
  background-color: white !important;
}

.one-quarter {
  width: 25%;
}
