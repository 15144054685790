$dimensions: 16 24 32 48 64 80 96 128;

%logo {
  margin-top: 5px;
}

.logo_navbar {
  @extend %logo;

  height: 30px;
  margin-top: 10px;

  @include desktop {
    height: 60px;
  }
}

.logo_sidebar {
  @extend %logo;

  height: 90px;
}

.help {
  height: 25px;
}
