.login-form{
    $min: 350px;
    min-height: $min;
    height: 60vh;
    min-width: $min;
    width: 40vw;
    margin-top: 100px;

    & + div{
        text-align: center;
        text-decoration: underline;
        margin-bottom: 50px;
    }
}