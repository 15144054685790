// @import "./resposive";

body {
  margin: 0px;
  padding: 0px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: transparent;
  overflow: hidden;

  & .page-background {
    height: 100%;
    max-width: 130% !important;
    width: 130% !important;
    overflow: hidden;
    position: relative;
    left: -20px;
    display: none;
    object-fit: cover;
    animation: backgroundAnimation 8s infinite;
  }

  & .darkener {
    background: rgba($color: #000000, $alpha: 0.15);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes backgroundAnimation {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(-110px, 0px);
  }
}

.login-btn {
  text-decoration: none;
  color: white !important;
  font-size: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 10;
  @include tablet {
    right: 60px;
  }
  @include desktop {
    right: 80px;
  }
  &:after {
    content: "";
    background-color: white;
    height: 1px;
    width: 0%;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transition: 300ms all;
  }
  &:hover:after {
    width: 100%;
    left: 0%;
  }
}

.texts {
  color: white;
  width: 100%;
  text-align: center;
  @include tablet {
    width: 70%;
  }
  @include desktop {
    width: 50%;
  }

  & h1 {
    font-size: 50px;
    margin-top: 30vh;
    margin-bottom: 0px;
    font-family: "Playfair Display", serif;
    font-weight: 10;
    @include tablet {
      font-size: 80px;
    }
  }

  & h2 {
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 10;
    @include tablet {
      font-size: 50px;
    }
  }
}
