div.dt-top-container {
  display: flex;
  justify-content: space-between;
}

div.dt-center-in-div {
  margin-right: 10px;
  margin-left: auto;

  @include mobile {
    margin: 0;
  }
}

div.dt-filter-spacer {
  margin: 10px 0;
}

.dataTableContainer {
  display: flex;
  justify-content: center;
}

.dataTableBox {
  background: white;
  width: 1350px;
  margin-bottom: 20px;
}

.colorTable {
  color: #e89d24 !important;
}
