.heightBox {
  height: 370px;
}

.middlePositionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
