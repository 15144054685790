//Functions
// @import "../../node_modules/bulma-scss/utilities/functions";
//Variables
@import "./scss/1-helpers/variables";
@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/utilities/all";

@import "./scss/1-helpers/variables_derivadas";
@import "../../node_modules/bulma/sass/form/shared";
@import "../../node_modules/bulma/sass/form/tools";
//Styles Bulma
@import "../../node_modules/bulma/sass/form/input-textarea.sass";
@import "../../node_modules/bulma/sass/grid/columns.sass";
@import "../../node_modules/bulma/sass/layout/hero.sass";
@import "../../node_modules/bulma-tooltip/dist/css/bulma-tooltip.min.css";

//Base
@import "./scss/2-base/generic";
@import "../../node_modules/bulma/sass/base/_all.sass";
//Elements
@import "./scss/3-elements/containers";
@import "./scss/3-elements/images";
@import "./scss/3-elements/lists";
@import "./scss/3-elements/links";

@import "./scss/1-helpers/lightstyle";

@import "../../node_modules/bulma/sass/elements/button.sass";
@import "../../node_modules/bulma/sass/elements/title.sass";
@import "../../node_modules/bulma/sass/elements/image.sass";
@import "../../node_modules/bulma/sass/elements/box.sass";
@import "../../node_modules/bulma/sass/elements/table.sass";

@import "../../node_modules/bulma/sass/form/select.sass";
@import "../../node_modules/bulma/sass/form/checkbox-radio.sass";
@import "../../node_modules/bulma/sass/form/file.sass";

@import "../../node_modules/bulma/sass/elements/icon.sass";
@import "../../node_modules/bulma/sass/elements/tag.sass";
@import "../../node_modules/bulma/sass/components/tabs.sass";
@import "../../node_modules/bulma/sass/elements/content.sass";

//Components
@import "./scss/3-elements/operations";
@import "./scss/4-components/navbar";
@import "./scss/4-components/sidebar";
@import "./scss/4-components/image_text_inline";
@import "./scss/4-components/modal";
@import "./scss/4-components/timeline";
@import "../../node_modules/bulma/sass/components/card.sass";
@import "./scss/4-components/receiveSendData";
@import "../../node_modules/bulma/sass/components/dropdown.sass";
@import "./scss/4-components/dataTable";
@import "./scss/4-components/multiselect";
@import "./scss/4-components/dataTablePagination";
@import "../../node_modules/bulma/sass/components/pagination.sass";
//Pages
@import "./scss/5-pages/landing";
@import "./scss/5-pages/login";
@import "./scss/5-pages/dashboard";

//Helpers
@import "../../node_modules/bulma/sass/helpers/spacing.sass";
@import "../../node_modules//bulma/sass/helpers/flexbox.sass";
@import "../../node_modules/bulma/sass/helpers/color.sass";

html,
body {
  font-size: 100%;
  font-family: $family-primary;

  @include widescreen {
    font-size: 105%;
  }

  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  #root {
    height: 100%;
  }
}
