.fullwidth-select{
  width: 100%;
  @include mobile {
    width: 100vw;
  }
}
.fullsize-select{
  width: 100%;
  height: 100%!important;
  @include mobile {
    width: 100vw;
  }
}