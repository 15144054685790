#App {
  min-height: 100vh;
  background-color: #e6e6e6;
}

#page-wrap {
  overflow-y: hidden;
  overflow-x: hidden;
}

.bm-accordion {
  background: $zamtest-blue;
  color: white;
  text-align: center;
}

.bm-accordion:hover {
  color: white;
  background: $zamtest-blue;
  border-radius: 10px;
}

.bm-item {
  display: inline-block;
  text-align: start;
  text-decoration: none;
  margin-bottom: 10px;
  color: white;
  transition: color 0.2s;
  font-size: 18px;
  padding: 8px 0px 8px 5px;
}

.bm-item:hover {
  color: black;
  background: white;
  border-radius: 10px;
  text-decoration: none;
}

.select-item:hover {
  background-color: transparent !important;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 28px;
}

.bm-burger-bars {
  background: white;
  border: solid 1px white;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: white;
}

.bm-menu {
  background: $zamtest-blue;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 2px 0px 18px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 0px 18px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 0px 18px 1px rgba(0, 0, 0, 0.75);
}
.bm-menu::-webkit-scrollbar {
  display: none;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-logo {
  padding: 0px;
  background-color: $zamtest-blue;
}

.bm-logo:hover {
  background-color: $zamtest-blue;
}
