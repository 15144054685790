// $table-striped-row-even-background-color: red;
// $table-striped-row-odd-background-color: blue;
// $table-row-active-color: green;

.dataTable-container {
  min-width: auto;
  width: 100%;
}

.dataTable-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.dataTable-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dataTable-content {
  overflow-x: scroll;
  width: 100%;
  @include desktop {
    overflow-x: hidden;
  }
}

.dataTable-content-empty {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-weight: bolder;
}
