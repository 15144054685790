.navbar_exit {
  margin-top: 15px;
  height: min-content;
  display: flex;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;

  & img {
    margin-left: 10px;
    margin-right: 10px;
  }

  & p {
    color: white;
  }

  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.3);
  }
}
