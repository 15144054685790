@import "../../../resources/scss/1-helpers/variables";

.searchBar_container {
  position: relative;
}

.searchBar_box_container {
  position: absolute;
  top: -10px;
  background-color: $white;
  border-color: $blue-info !important;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100 !important;
  max-height: 260px;
  max-width: 100%;
  margin: 0 !important;

  & table {
    background-color: $white;
  }
}

.searchBar_item {
  border-radius: 1em;
  border-color: transparent !important;
}

.searchBar_item:hover {
  background-color: $blue-info !important;
  color: white;
  cursor: pointer;
}
.is_selected {
  background-color: $green-primary;
  color: white;
}

.searchBar_error_container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-radius: 1em;
  background-color: $blue-info;
  color: white;
  padding: 5px;
  justify-content: center;
}
